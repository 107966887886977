body, html {
  background-color: deepskyblue;
}

.sft-section {
  color: white;
  box-shadow: rgba(0,0,0,0.8) 0 0 30px;
}

/* .carousel-inner .item {
  transform: translate3d(0,0,0) !important;
}*/

.carousel-inner {
  transition: height 1s ease-in-out;
}

.sft-feature-bg {
  background-color: white;
  border-radius: 50%;
  box-shadow: rgba(0,0,0,0.8) 0 0 20px;
  max-width: 250px;
  margin: auto;
}